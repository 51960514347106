import React, { useEffect, useRef } from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import TopNav from 'components/Navbars/TopNav';
import Navbar from 'components/Navbars/SaasNav';
import AboutHeader from 'components/Saas/AboutHeader';
import Community from 'components/Saas/Community';
import Philosophy from 'components/Saas/Philosophy';
import ChooseUs from 'components/Saas/ChooseUs';
import Clients from 'components/Saas/Clients';
import Numbers from 'components/Saas/Numbers';
import Culture from 'components/Saas/Culture';
import Team from 'components/Saas/Team';
import Contact from 'components/Saas/Contact';
import Footer from 'components/Saas/Footer';

const Pageprivacypolicy = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  return (
    <MainLayout>
      <TopNav style="5" />
      <Navbar navbarRef={navbarRef} />
      <main className="about-page style-5">
      <div
      style={{
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        margin: '0',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        color: '#333',
      }}
    >
      <div
        style={{
          maxWidth: '800px',
          margin: 'auto',
          padding: '20px',
          background: '#fff',
          borderRadius: '5px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <h1 style={{ textAlign: 'center', color: '#2c3e50' }}>Privacy Policy</h1>
        <p>
          At Woxox CRM, we value your privacy and are committed to safeguarding your personal information. This
          Privacy Policy explains how we collect, use, disclose, and protect your data when you use our services. By
          using Woxox CRM, you agree to the terms outlined in this policy.
        </p>

        <h2 style={{ color: '#2c3e50' }}>1. Information We Collect</h2>
        <p>We collect the following types of information to provide and improve our services:</p>
        <ul style={{ marginBottom: '1em', paddingLeft: '20px' }}>
          <li>
            <strong>Personal Information:</strong>
            <ul style={{ paddingLeft: '20px' }}>
              <li>Name, email address, phone number, billing address, and payment details.</li>
              <li>Login credentials for accessing your Woxox CRM account.</li>
            </ul>
          </li>
          <li>
            <strong>Business Data:</strong>
            <ul style={{ paddingLeft: '20px' }}>
              <li>Information you upload to our platform, including customer details, leads, sales data, and communications.</li>
            </ul>
          </li>
          <li>
            <strong>Usage Data:</strong>
            <ul style={{ paddingLeft: '20px' }}>
              <li>Details about how you interact with our platform, such as login times, pages visited, and features used.</li>
            </ul>
          </li>
          <li>
            <strong>Device Information:</strong>
            <ul style={{ paddingLeft: '20px' }}>
              <li>Information about your device, including IP address, browser type, operating system, and device identifiers.</li>
            </ul>
          </li>
        </ul>

        <h2 style={{ color: '#2c3e50' }}>2. How We Use Your Information</h2>
        <p>We use the collected information to:</p>
        <ul style={{ marginBottom: '1em', paddingLeft: '20px' }}>
          <li>Provide, maintain, and improve our services.</li>
          <li>Process payments and manage billing.</li>
          <li>Communicate with you regarding updates, support, and promotional offers.</li>
          <li>Ensure security and prevent fraudulent or unauthorized activities.</li>
          <li>Analyze usage trends to enhance user experience.</li>
        </ul>

        <h2 style={{ color: '#2c3e50' }}>3. Sharing Your Information</h2>
        <p>We do not sell or rent your personal information. However, we may share it under the following circumstances:</p>
        <ul style={{ marginBottom: '1em', paddingLeft: '20px' }}>
          <li>
            <strong>With Service Providers:</strong> Third-party providers who assist us with hosting, payment processing,
            customer support, and analytics.
          </li>
          <li>
            <strong>Legal Obligations:</strong> When required by law or to respond to valid legal processes, such as subpoenas
            or court orders.
          </li>
          <li>
            <strong>Business Transfers:</strong> In case of a merger, acquisition, or sale of assets, your information may be
            transferred as part of the transaction.
          </li>
        </ul>

        <h2 style={{ color: '#2c3e50' }}>4. Data Security</h2>
        <p>We implement industry-standard security measures to protect your information, including:</p>
        <ul style={{ marginBottom: '1em', paddingLeft: '20px' }}>
          <li>Data encryption during transmission (e.g., SSL).</li>
          <li>Secure data storage and regular security audits.</li>
          <li>Restricted access to your data, limited to authorized personnel.</li>
        </ul>
        <p>While we strive to protect your information, no method of electronic storage or transmission is 100% secure.</p>

        <h2 style={{ color: '#2c3e50' }}>5. Your Rights</h2>
        <p>As a user, you have the following rights regarding your data:</p>
        <ul style={{ marginBottom: '1em', paddingLeft: '20px' }}>
          <li>
            <strong>Access:</strong> Request a copy of the personal information we hold about you.
          </li>
          <li>
            <strong>Correction:</strong> Update or correct your personal information.
          </li>
          <li>
            <strong>Deletion:</strong> Request the deletion of your account and associated data (subject to legal
            obligations).
          </li>
          <li>
            <strong>Opt-out:</strong> Unsubscribe from promotional communications at any time.
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us at{' '}
          <a href="mailto:info@woxox.com" style={{ color: '#3498db', textDecoration: 'none' }}>
            info@woxox.com
          </a>
          .
        </p>

        <h2 style={{ color: '#2c3e50' }}>6. Data Retention</h2>
        <p>
          We retain your data for as long as necessary to fulfill the purposes outlined in this policy or as required by
          law. Upon termination of your account, we will delete your data unless retention is legally required.
        </p>

        <h2 style={{ color: '#2c3e50' }}>7. Cookies and Tracking Technologies</h2>
        <p>
          We use cookies and similar technologies to enhance user experience, analyze trends, and track usage. You can manage
          cookie preferences through your browser settings.
        </p>

        <h2 style={{ color: '#2c3e50' }}>8. Third-Party Services</h2>
        <p>
          Woxox CRM may integrate with third-party tools and platforms. Please note that their privacy practices are
          governed by their respective policies, and we are not responsible for their actions.
        </p>

        <h2 style={{ color: '#2c3e50' }}>9. Children’s Privacy</h2>
        <p>
          Woxox CRM is not intended for individuals under the age of 18. We do not knowingly collect personal information from
          children.
        </p>

        <h2 style={{ color: '#2c3e50' }}>10. Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Significant changes will be communicated via email or through
          our platform. Please review this policy periodically for updates.
        </p>

        <h2 style={{ color: '#2c3e50' }}>11. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us:</p>
        <ul style={{ marginBottom: '1em', paddingLeft: '20px' }}>
          <li>
            <strong>Email:</strong>{' '}
            <a href="mailto:info@woxox.com" style={{ color: '#3498db', textDecoration: 'none' }}>
              info@woxox.com
            </a>
          </li>
          <li>
            <strong>Address:</strong> Woxox General Hospital Rd, Opp. St Joseph School, Near Indian Oil Pump, Jai Vihar,
            Kunnukuzhy, Thiruvananthapuram, Kerala 695035
          </li>
        </ul>

        <p>Thank you for trusting Woxox CRM. Your privacy is our priority.</p>
      </div>
    </div>
      </main>
      <Footer noWave />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title>Iteck - About 5</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default Pageprivacypolicy;